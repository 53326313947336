<template>
  <v-sheet>
    <v-form ref="form">
      <div v-if="menuIncoming">
        <v-card-text>
          <div>
            <div class="custom-header-tanpa-back">
              <div class="header-title">Incoming</div>
            </div>
          </div>
          <br />
          <br />
          <br />

          <v-text-field
            outlined
            v-model="search_table"
            append-icon="mdi-magnify"
            label="No. Bagtag / Awb"
            single-line
            hide-details
            @input="getDataTable"
          ></v-text-field>
          <br />
          <div class="d-flex flex-column">
            <v-btn color="warning" @click="showScanner = true">
              <v-icon left>mdi-barcode</v-icon>Scan Barcode
            </v-btn>
          </div>
          <br />

          <stream-barcode-reader
            v-if="showScanner"
            @decode="onBarcodeDetected"
            @close="showScanner = false"
            class="scanner"
            :stop-after-scan="true"
          ></stream-barcode-reader>
          <br />
          <div v-if="isLoading" class="loader-overlay"></div>
          <v-divider class="mx-4 mb-1"></v-divider>
          <div v-for="item in items_data" :key="item.nosb">
            <v-row dense>
              <v-col cols="12">
                <v-card>
                  <v-card-title class="no-padding">
                    <v-chip-group>
                      <v-chip
                        color="red"
                        text-color="white"
                        @click="showListAwb(item)"
                        >{{ item.nosb }}</v-chip
                      >
                    </v-chip-group></v-card-title
                  >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" align="left" class="no-padding">{{
                        item.tgldm
                      }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" align="left" class="no-padding">{{
                        item.asaldm
                      }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" align="left" class="no-padding">{{
                        item.tujuandm
                      }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" align="left" class="no-padding"
                        >{{ item.berat }} (Kg)</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col cols="12" align="left" class="no-padding"
                        >{{ item.awb }} (Awb)</v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <br />
          <br />
          <br />
          <div v-if="data" align="center">
            <img
              :src="not_found"
              alt="Photo"
              :style="{ width: '300px', height: 'auto' }"
            />
          </div>
        </v-card-text>
      </div>
      <div v-if="menuLacak">
        <v-card-text>
          <div>
            <div class="custom-header">
              <v-btn @click="ShowIncoming" icon>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <div class="header-title">List Awb</div>
            </div>
            <br />
            <br />
            <br />

            <v-text-field
              outlined
              v-model="search_table_nosb"
              append-icon="mdi-magnify"
              label="No. Awb"
              single-line
              hide-details
              @input="getDataTableSB"
            ></v-text-field>
            <br />
            <div class="d-flex flex-column">
              <v-btn color="warning" @click="showScannerAwb = true">
                <v-icon left>mdi-barcode</v-icon>Scan Barcode
              </v-btn>
            </div>
            <br />

            <stream-barcode-reader
              v-if="showScannerAwb"
              @decode="onBarcodeDetectedAwb"
              @close="showScannerAwb = false"
              class="scanner"
              :stop-after-scan="true"
            ></stream-barcode-reader>
            <br />
            <div v-if="isLoading" class="loader-overlay"></div>
            <v-divider class="mx-4 mb-1"></v-divider>
            <div v-for="item in items_data_sb" :key="item.noawb">
              <v-row dense>
                <v-col cols="12">
                  <v-card>
                    <v-card-title class="no-padding">
                      <v-chip-group>
                        <v-chip
                          color="red"
                          text-color="white"
                          @click="SimpanAwbIncoming(item.noawb)"
                          >{{ item.noawb }}</v-chip
                        >
                      </v-chip-group></v-card-title
                    >
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" align="left" class="no-padding">{{
                          item.dateairwaybill
                        }}</v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" align="left" class="no-padding">{{
                          item.asal
                        }}</v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" align="left" class="no-padding">{{
                          item.tujuan
                        }}</v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" align="left" class="no-padding">{{
                          item.service
                        }}</v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" align="left" class="no-padding"
                          >{{ item.pieces }} (Koli)</v-col
                        >
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <br />
            <br />
            <br />
            <div v-if="data_sb" align="center">
              <img
                :src="not_found"
                alt="Photo"
                :style="{ width: '300px', height: 'auto' }"
              />
            </div>
          </div>
        </v-card-text>
      </div>
    </v-form>
    <br />
    <br />
  </v-sheet>
</template>
  
  <script>
import axios from "axios";
import swal from "sweetalert";
import { StreamBarcodeReader } from "vue-barcode-reader"; // Import the StreamBarcodeReader component

export default {
  components: { StreamBarcodeReader },
  data() {
    return {
      selectedItem: null,
      items: [],
      search: "",
      loading: false,
      search_table: "",
      search_table_nosb: "",

      selectedStatus: null,
      statuss: [],
      searchstatus: "",

      selectedPenerima: null,
      statuspenerima: [],
      searchpenerima: "",

      showScanner: false,
      showScannerAwb: false,
      isLoading: false,

      menuIncoming: true,
      menuLacak: false,
      nosb: "",
      nodetilsb: "",
      plat_no: "",
      file: null,
      photo: "",
      imageUrl: "",

      nama_penerima: "",
      menu_edit_date: "",
      edit_date: null,
      jam: "",
      menit: "",
      dateFormat: "yyyy-mm-dd",
      today: new Date().toISOString().substr(0, 10),

      items_data: [],
      items_data_sb: [],
      data: false,
      data_sb: false,
      id_karyawan: localStorage.getItem("ID"),
      role_id: localStorage.getItem("role_id"),
      url: localStorage.getItem("url"),
      provinsi_id: localStorage.getItem("provinsi_id"),
      lokasi: localStorage.getItem("lokasi"),
      MediaKeys: localStorage.getItem("key"),
      id_perusahaan: localStorage.getItem("id_perusahaan"),
      not_found:
        "https://img.freepik.com/free-vector/hand-drawn-no-data-concept_52683-127829.jpg?size=626&ext=jpg&ga=GA1.1.1219781391.1697861332&semt=ais",
    };
  },
  created: function () {
    // this.getDataTable();
  },
  mounted() {},

  methods: {
    onBlur() {
      if (!this.selectedItem) {
        this.search = "";
      }
      if (!this.selectedStatus) {
        this.searchstatus = "";
      }
      if (!this.selectedPenerima) {
        this.searchpenerima = "";
      }
    },
    Home() {
      this.$router.push("/");
    },
    async searchItems() {
      this.loading = true;
      this.items = await this.fetchStatusCpFromDatabase(
        this.search,
        this.searchstatus
      );
      this.loading = false;
    },
    async searchStatus() {
      this.loading = true;
      this.statuss = await this.fetchStatusFromDatabase(this.searchstatus);
      this.loading = false;
    },
    async searchPenerima() {
      this.loading = true;
      this.statuspenerima = await this.fetchStatusPenerimaFromDatabase(
        this.searchpenerima
      );
      this.loading = false;
    },
    async getDataTable() {
      this.isLoading = true; // Mengaktifkan loader
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/get_list_incoming",
          {
            nobagtag: this.search_table,
          },
          { headers }
        );
        this.response = response.data;
        //console.log(response.data.status);
        if (response.data.status === "fail") {
          //this.$router.push("/customer");
          this.items_data = [];
          this.data = true;
        } else {
          this.data = false;
          this.items_data = response.data.data;

          //localStorage.setItem("authenticated",true)
          //this.$router.push('/home')
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async getDataTableSB() {
      this.isLoading = true; // Mengaktifkan loader
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/get_detil_list_incoming",
          {
            nobagtag: this.nodetilsb,
          },
          { headers }
        );
        this.response = response.data;
        //console.log(response.data.status);
        if (response.data.status === "fail") {
          //this.$router.push("/customer");
          this.items_data_sb = [];
          this.data_sb = true;
        } else {
          this.data_sb = false;
          this.items_data_sb = response.data.data;
          if (this.search_table_nosb != "") {
            this.SimpanAwbIncoming(this.search_table_nosb);
          }

          //localStorage.setItem("authenticated",true)
          //this.$router.push('/home')
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    onBarcodeDetected(result) {
      this.search_table = result; // Populate the search field with the scanned barcode
      console.log(result);
      this.showScanner = false; // Close the scanner
      this.getDataTable(); // Trigger the search
    },
    onBarcodeDetectedAwb(result) {
      this.search_table_sb = result; // Populate the search field with the scanned barcode
      console.log(result);
      this.showScannerAwb = false; // Close the scanner
      this.SimpanAwbIncoming(result); // Trigger the search
    },
    ShowIncoming() {
      this.menuLacak = false;
      this.menuIncoming = true;
      this.search_table = "";
      this.getDataTable();
    },
    async showListAwb(user) {
      this.menuIncoming = false;
      this.menuLacak = true;
      this.nodetilsb = user.nosb;
      this.getDataTableSB();
    },
    hitungTotal() {
      // Pastikan bahwa awal_liter, akhir_liter, dan harga berisi angka
      const awalLiter = parseFloat(this.qty_pickup);
      const harga = parseFloat(this.harga_pickup);

      // Lakukan perhitungan hanya jika nilai-nilai valid
      if (!isNaN(awalLiter) && !isNaN(harga)) {
        const total = awalLiter * harga;
        // Update nilai total
        this.total_pickup = total.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
      } else {
        this.total_pickup = "0"; // Set total menjadi kosong jika ada nilai yang tidak valid
      }
      console.log("Awal", awalLiter);
      console.log("harga", harga);
      console.log("total", this.total_pickup);
    },
    async SimpanAwbIncoming(awb) {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/simpan_awb_incoming",

          {
            noawb: awb,
            lokasi: this.lokasi,
            nosb: this.nodetilsb,
            username: this.id_karyawan,
          },
          { headers }
        );
        this.response = response.data;
        console.log(response.data.status);
        if (response.data.status === "fail") {
          swal({
            title: "Error",
            text: "Update Tidak Berhasil",
            icon: "warning",
          });
        } else {
          swal({
            title: "Success",
            text: "Update Berhasil",
            icon: "success",
          });
          this.search_table_nosb = "";
          this.getDataTableSB();
        }
        this.isLoading = false; // Mengaktifkan loader
      } catch (err) {
        console.log(err);
      }
    },
    async fetchStatusFromDatabase(search) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": this.MediaKeys,
      };
      const response = await axios.get(this.url + "master/get_status_deliver", {
        params: { search },
        headers: headers,
      });
      //console.warn(params)
      return response.data.data;
    },
    async fetchStatusCpFromDatabase(search, searchstatus) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": this.MediaKeys,
      };
      const response = await axios.get(this.url + "master/get_status", {
        params: { search, searchstatus },
        headers: headers,
      });
      //console.warn(params)
      return response.data.data;
    },
    async fetchStatusPenerimaFromDatabase(search) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": this.MediaKeys,
      };
      const response = await axios.get(
        this.url + "master/get_status_penerima",
        {
          params: { search },
          headers: headers,
        }
      );
      //console.warn(params)
      return response.data.data;
    },
  },
  watch: {
    search: function () {
      this.searchItems();
    },
    searchstatus: function () {
      this.searchStatus();
    },
    searchpenerima: function () {
      this.searchPenerima();
    },
  },
};
</script>
  
  <style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.scanner {
  position: relative;
  width: 100%;
  height: 300px;
}
</style>
             
<style scoped>
.no-padding {
  padding: 0; /* or padding: 0 !important; if needed */
}
</style>
  
  