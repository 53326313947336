<template>
  <v-sheet>
    <v-form ref="form">
      <div v-if="menuList">
        <v-card-text>
          <div>
            <div class="custom-header-tanpa-back">
              <div class="header-title">Delivery</div>
            </div>
          </div>
          <br />
          <br />
          <br />

          <v-text-field
            outlined
            v-model="search_table"
            append-icon="mdi-magnify"
            label="No. Awb"
            single-line
            hide-details
            @input="getDataTable"
          ></v-text-field>
          <br />
          <div class="d-flex flex-column">
            <v-btn color="warning" @click="showScanner = true">
              <v-icon left>mdi-barcode</v-icon>Scan Barcode
            </v-btn>
          </div>
          <br />

          <stream-barcode-reader
            v-if="showScanner"
            @decode="onBarcodeDetected"
            @close="showScanner = false"
            class="scanner"
            :stop-after-scan="true"
          ></stream-barcode-reader>
          <br />
          <div v-if="isLoading" class="loader-overlay"></div>
          <v-divider class="mx-4 mb-1"></v-divider>
          <div v-for="item in items_data" :key="item.noawb">
            <v-row dense>
              <v-col cols="12">
                <v-card>
                  <v-card-title class="no-padding">
                    <v-chip-group>
                      <v-chip
                        color="red"
                        text-color="white"
                        @click="showEdit(item)"
                        >{{ item.noawb }}</v-chip
                      >
                    </v-chip-group></v-card-title
                  >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" align="left" class="no-padding">
                        <v-chip-group
                          ><v-chip color="green" text-color="white">{{
                            item.status
                          }}</v-chip></v-chip-group
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" align="left" class="no-padding">{{
                        item.dateairwaybill
                      }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" align="left" class="no-padding">{{
                        item.asal
                      }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" align="left" class="no-padding">{{
                        item.tujuan
                      }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" align="left" class="no-padding">{{
                        item.service
                      }}</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <br />
          <br />
          <br />
          <div v-if="data" align="center">
            <img
              :src="not_found"
              alt="Photo"
              :style="{ width: '300px', height: 'auto' }"
            />
          </div>
        </v-card-text>
      </div>
      <v-row justify="center">
        <div v-if="dialogEdit">
          <v-card>
            <div class="custom-header">
              <v-btn @click="showList" icon>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <div class="header-title">Update Status Pengiriman</div>
            </div>
            <br />
            <br />
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      label="No. AWB"
                      v-model="noawb"
                      readonly
                    ></v-text-field>

                    <v-autocomplete
                      v-model="selectedItem"
                      :items="items"
                      :loading="loading"
                      :search-input.sync="search"
                      :no-filter="true"
                      item-text="status_pengiriman"
                      item-value="id"
                      @blur="onBlur"
                      label="Status"
                      clearable
                      outlined
                    ></v-autocomplete>
                    <div v-if="search === 'Diterima'">
                      <v-autocomplete
                        v-model="selectedPenerima"
                        :items="statuspenerima"
                        :loading="loading"
                        :search-input.sync="searchpenerima"
                        :no-filter="true"
                        item-text="nama"
                        item-value="id"
                        @blur="onBlur"
                        label="Status Penerima"
                        clearable
                        outlined
                      ></v-autocomplete>
                      <v-text-field
                        outlined
                        label="Nama Penerima"
                        v-model="nama_penerima"
                      ></v-text-field>
                    </div>
                    <v-menu
                      v-model="menu_edit_date"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          outlined
                          v-model="edit_date"
                          label="Tgl.Status"
                          :format="dateFormat"
                          v-on="on"
                          readonly
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="edit_date"
                        @input="menu_edit_date = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          outlined
                          v-model="jam"
                          label="HH"
                          single-line
                          type="number"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          outlined
                          v-model="menit"
                          label="mm"
                          single-line
                          type="number"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <div v-if="imageUrl">
                      <a :href="imageUrl" target="_blank">
                        <img
                          :src="imageUrl"
                          alt="Photo"
                          :style="{ width: '300px', height: 'auto' }"
                        />
                      </a>
                    </div>
                    <div v-else>
                      <p>No image available</p>
                    </div>
                    <br />
                    <br />
                    <v-file-input
                      v-model="file"
                      show-size
                      counter
                      accept="image/*"
                      label="Ambil Photo"
                      outlined
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showList">Close</v-btn>
              <v-btn color="blue darken-1" text @click="Upload">Submit</v-btn>
            </v-card-actions>
          </v-card>
          <br />
          <br />
        </div>
      </v-row>
    </v-form>
    <br />
    <br />
  </v-sheet>
</template>
  
  <script>
import axios from "axios";
import swal from "sweetalert";
import { StreamBarcodeReader } from "vue-barcode-reader"; // Import the StreamBarcodeReader component

export default {
  components: { StreamBarcodeReader },
  data() {
    return {
      selectedItem: null,
      items: [],
      search: "",
      loading: false,
      search_table: "",

      selectedStatus: null,
      statuss: [],
      searchstatus: "",

      selectedPenerima: null,
      statuspenerima: [],
      searchpenerima: "",

      showScanner: false,
      isLoading: false,

      menuList: true,
      dialogEdit: false,
      noawb: "",
      id: "",
      plat_no: "",
      file: null,
      photo: "",
      imageUrl: "",

      nama_penerima: "",
      menu_edit_date: "",
      edit_date: null,
      jam: "",
      menit: "",
      dateFormat: "yyyy-mm-dd",
      today: new Date().toISOString().substr(0, 10),

      items_data: [],
      data: false,
      id_karyawan: localStorage.getItem("ID"),
      role_id: localStorage.getItem("role_id"),
      url: localStorage.getItem("url"),
      provinsi_id: localStorage.getItem("provinsi_id"),
      lokasi: localStorage.getItem("lokasi"),
      MediaKeys: localStorage.getItem("key"),
      id_perusahaan: localStorage.getItem("id_perusahaan"),
      not_found:
        "https://img.freepik.com/free-vector/hand-drawn-no-data-concept_52683-127829.jpg?size=626&ext=jpg&ga=GA1.1.1219781391.1697861332&semt=ais",
    };
  },
  created: function () {
    // this.getDataTable();
  },
  mounted() {},

  methods: {
    onBlur() {
      if (!this.selectedItem) {
        this.search = "";
      }
      if (!this.selectedStatus) {
        this.searchstatus = "";
      }
      if (!this.selectedPenerima) {
        this.searchpenerima = "";
      }
    },
    Home() {
      this.$router.push("/");
    },
    async searchItems() {
      this.loading = true;
      this.items = await this.fetchStatusCpFromDatabase(
        this.search,
        this.id_perusahaan
      );
      this.loading = false;
    },
    async searchStatus() {
      this.loading = true;
      this.statuss = await this.fetchStatusFromDatabase(this.searchstatus);
      this.loading = false;
    },
    async searchPenerima() {
      this.loading = true;
      this.statuspenerima = await this.fetchStatusPenerimaFromDatabase(
        this.searchpenerima
      );
      this.loading = false;
    },

    async getDataTable() {
      this.isLoading = true; // Mengaktifkan loader
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/get_list_delivery",
          {
            status_id: this.selectedItem,
            m_users_id: this.id_karyawan,
            role_id: this.role_id,
            noawb: this.search_table,
            id_perusahaan: this.id_perusahaan,
          },
          { headers }
        );
        this.response = response.data;
        //console.log(response.data.status);
        if (response.data.status === "fail") {
          //this.$router.push("/customer");
          this.items_data = [];
          this.data = true;
        } else {
          this.data = false;
          this.items_data = response.data.data;

          //localStorage.setItem("authenticated",true)
          //this.$router.push('/home')
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    onBarcodeDetected(result) {
      this.search_table = result; // Populate the search field with the scanned barcode
      console.log(result);
      this.showScanner = false; // Close the scanner
      this.getDataTable(); // Trigger the search
    },
    showList() {
      this.menuList = true;
      this.dialogEdit = false;
      this.getDataTable();
    },
    async showEdit(user) {
      this.dialogEdit = true;
      this.menuList = false;
      this.noawb = user.noawb;
      this.id = user.id;
      this.nama_penerima = user.keterangan;
      this.selectedItem = user.id_status_pengiriman;
      this.selectedPenerima = user.id_status_penerima;
      console.log(user.id);
      if (user.upload !== null) {
        this.imageUrl =
          "https://beos.ptppi.co.id/api/uploads/" + user.bukti_penerima;
        console.log(user);
      }
    },
    hitungTotal() {
      // Pastikan bahwa awal_liter, akhir_liter, dan harga berisi angka
      const awalLiter = parseFloat(this.qty_pickup);
      const harga = parseFloat(this.harga_pickup);

      // Lakukan perhitungan hanya jika nilai-nilai valid
      if (!isNaN(awalLiter) && !isNaN(harga)) {
        const total = awalLiter * harga;
        // Update nilai total
        this.total_pickup = total.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
      } else {
        this.total_pickup = "0"; // Set total menjadi kosong jika ada nilai yang tidak valid
      }
      console.log("Awal", awalLiter);
      console.log("harga", harga);
      console.log("total", this.total_pickup);
    },
    async Upload() {
      if (!this.selectedItem) {
        swal("Please Input Required Field");
        return;
      }

      this.isLoading = true; // Mengaktifkan loader

      try {
        let formData = new FormData();
        formData.append("file", this.file);

        const response = await axios.post(
          // `${this.url}uploadfile.php`,
          `https://woles.ptppi.co.id/api/uploadfile_beos.php`,
          formData
        );
        this.response = response.data;
        console.warn(response.data);

        this.isLoading = false; // Menonaktifkan loader

        switch (response.data.message) {
          case "File already exists.":
            swal({
              title: "Error",
              text: "File already exists",
              icon: "warning",
            });
            break;
          case "Data sudah tersedia":
            swal({
              title: "Error",
              text: "Data already exists",
              icon: "warning",
            });
            break;
          case "Only Image files are allowed.":
            swal({
              title: "Error",
              text: "Only Image files are allowed",
              icon: "warning",
            });
            break;
          default:
            if (response.data.status === "success") {
              swal({
                title: "Success",
                text: "Save Success",
                icon: "success",
              });
              this.photo = response.data.file_name;
              console.info(this.photo);
              this.HandleDriver();
            } else {
              swal({
                title: "Error",
                text: "Cek Lagi File Yang Di Upload",
                icon: "warning",
              });
            }
            break;
        }
      } catch (err) {
        this.isLoading = false; // Pastikan loader dinonaktifkan pada kesalahan
        console.error(err);
        swal({
          title: "Error",
          text: "An error occurred during the upload process.",
          icon: "error",
        });
      }
    },
    async HandleDriverBDP() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/entry_pod",

          {
            no_awb: this.noawb,
            id: this.id,
            tgl: this.edit_date,
            jam: this.jam,
            menit: this.menit,
            status: this.selectedItem,
            lokasi: this.lokasi,
            ket: this.selectedPenerima,
            nama_penerima: this.nama_penerima,
            keterangan_perubahan: this.keterangan_perubahan,
            photo: this.photo,
            username: this.id_karyawan,
          },
          { headers }
        );
        this.response = response.data;
        console.log(response.data.status);
        if (response.data.status === "fail") {
          swal({
            title: "Error",
            text: "Approve Tidak Berhasil",
            icon: "warning",
          });
        } else {
          swal({
            title: "Success",
            text: "Approve Berhasil",
            icon: "success",
          });
          this.showList();
        }
        this.isLoading = false; // Mengaktifkan loader
      } catch (err) {
        console.log(err);
      }
    },
    async HandleDriver() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/entry_pod",

          {
            no_awb: this.noawb,
            tgl: this.edit_date,
            jam: this.jam,
            menit: this.menit,
            status: this.selectedItem,
            lokasi: this.lokasi,
            ket: this.selectedPenerima,
            keterangan_perubahan: this.keterangan_perubahan,
            photo: this.photo,
            username: this.id_karyawan,

            no_pengiriman: this.noawb,
            id_status_pengiriman: this.selectedItem,
            id_status_penerima: this.selectedPenerima,
            nama_penerima: this.nama_penerima,
            id: this.id,
            id_user: this.id_karyawan,
            id_perusahaan: 1,
          },
          { headers }
        );
        this.response = response.data;
        console.log(response.data.status);
        if (response.data.status === "fail") {
          swal({
            title: "Error",
            text: "Approve Tidak Berhasil",
            icon: "warning",
          });
        } else {
          swal({
            title: "Success",
            text: "Approve Berhasil",
            icon: "success",
          });
          this.showList();
        }
        this.isLoading = false; // Mengaktifkan loader
      } catch (err) {
        console.log(err);
      }
    },
    async fetchStatusFromDatabase(search) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": this.MediaKeys,
      };
      const response = await axios.get(this.url + "master/get_status_deliver", {
        params: { search },
        headers: headers,
      });
      //console.warn(params)
      return response.data.data;
    },
    async fetchStatusCpFromDatabase(search, id_perusahaan) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": this.MediaKeys,
      };
      const response = await axios.get(this.url + "master/get_status", {
        params: { search, id_perusahaan },
        headers: headers,
      });
      //console.warn(params)
      return response.data.data;
    },
    async fetchStatusPenerimaFromDatabase(search) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": this.MediaKeys,
      };
      const response = await axios.get(
        this.url + "master/get_status_penerima",
        {
          params: { search },
          headers: headers,
        }
      );
      //console.warn(params)
      return response.data.data;
    },
  },
  watch: {
    search: function () {
      this.searchItems();
    },
    searchstatus: function () {
      this.searchStatus();
    },
    searchpenerima: function () {
      this.searchPenerima();
    },
  },
};
</script>
  
  <style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.scanner {
  position: relative;
  width: 100%;
  height: 300px;
}
</style>
             
<style scoped>
.no-padding {
  padding: 0; /* or padding: 0 !important; if needed */
}
</style>
  
  